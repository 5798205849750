@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR&display=swap');

body {
  background-image: url(images/web-back.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0; 
  font-family: 'Manrope', sans-serif; 
  height: 100vh;
}

.header {
  width: 100%;
  height: 3em;
  background-color: black;
  border-bottom: rgb(15, 191, 191) 1px solid;
}

.image{
  margin-left: 20px;
}

.website-title {
  color: white;
  font-size: 7vw;
  text-align: center;
  text-shadow: 0 0 10px azure, 0 0 10px azure;
  font-family: 'Noto Serif KR', serif;
}

.x {
  color: red;
  text-shadow: 0 0 10px rgb(246, 117, 117), 0 0 10px rgb(255, 117, 117), 0 0 5px white;
}

.launch-message {
  color: azure;
  font-size: 4vw; 
  text-align: center;
  text-shadow: 2px 2px 5px white, 0 0 5px azure;
}

.countdown-timer {
  width: 95%;
  max-width: 1200px; 
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Manrope', sans-serif;
}

.time-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: white;
  font-size: 5vw; 
  text-align: center;
  text-shadow: 0 0 5px white, 0 0 5px white, 0 5px 15px white, 5px 0 15px white;
}

.time {
  transition-duration: 0.5s; 
}

.time:hover {
  color: rgb(185, 183, 183);
}

.time-description {
  text-shadow: 2px 2px 5px white;
  font-size: 2vw; 
}

.footer {
  width: 100%;
  height: 3em;
  background-color: black;
  border-top: rgb(15, 191, 191) 1px solid;
  position: fixed; 
  bottom: 0;
}

